import AnciLombardiaLogo from '../assets/contacts/anciLombardiaLogo.jpg';
import RegioneLombardiaLogo from '../assets/contacts/regioneLombardiaLogo.jpg'; 

const contacts = {
  RegioneLombardia: {
    logo: RegioneLombardiaLogo,
    name: "Regione Lombardia",
    sede: "Piazza Città di Lombardia n. 1, <br> Palazzo Lombardia - 20124 Milano",
    telephone: "02 6765.1",
    fax: "02 3936162",
    site: "https://www.regione.lombardia.it/",
    pec: "ambiente_clima@pec.regione.lombardia.it"
  },

  AnciLombardia: {
    logo: AnciLombardiaLogo,
    name: "Anci Lombardia",
    sede: "Via Rovello 2, <br> 20121 Milano",
    telephone: "02 72629601",
    fax: "02 861629",
    site: "http://www.anci.lombardia.it/",
    pec: "posta@anci.lombardia.it"
  }
}

export default contacts;