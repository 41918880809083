import chroma from 'chroma-js';

export const colors = {
    buttonMAS1: '#0aa0db',
    buttonMAS2: '#0e24f0',
    buttonMAS3: '#0000ff',
    buttonMAS4: '#620ad6',
    buttonMAS5: '#ff00ff',
};

//select a color based on the value
// export const getColor = (value, municipalitySelected, grades, totalGrades) => {
//   let colors;
//   if (municipalitySelected) {
//     colors = ['#FFC8DD', '#FFAFCC', '#CDB4DB']; // colors for low, medium, high values
//     if (value < grades[1]) return colors[0];
//     else if (value < grades[2]) return colors[1];
//     else return colors[2];
//   } else {
//     colors = chroma.scale(['#ffffcc', '#253494']).colors(totalGrades);
//     console.log('Grades: ', grades);
//     const index = grades.findIndex(grade => value < grade);
//     return colors[index !== -1 ? index : totalGrades - 1];
//   }
// };
export const getColor = (value, municipalitySelected, grades, totalGrades) => {
  // console.log('MunicipalitySelected: ', municipalitySelected);
  //console.log('Grades: ', grades);
  if (municipalitySelected) {

    if (value < grades[1]) return '#FFF97D'; // color for low values //#FFC8DD //#FFEF85  //#F0ADC1 //#FFF97D

    else if (value < grades[2]) return '#EDAD7C'; // color for medium values //#FFAFCC   //#AE7DA8 //#EDAD7C

    else return '#DB607B'; // color for high values //#CDB4DB //#794EE9 //#6B4C8E  //#DB607B
    
  } else {
    const colors = chroma.scale(['#ffffcc', '#253494']).colors(grades.length-1);
    if (value === grades[grades.length - 1]) {
      return colors[colors.length - 1];
    }
    const index = grades.findIndex(grade => value < grade);
    if (index === -1 || index === 0) {
      return colors[0];
    }
    return colors[index-1]; 
  }
};