// const roundedMinValue = Math.floor(minValue);
//   const roundedMaxValue = Math.ceil(maxValue);
export function createGrades(minValue, maxValue, totalGrades) {
    const step = (maxValue - minValue) / (totalGrades);
    let grades = Array.from({length: totalGrades + 1}, (_, i) => Number((minValue + i * step))); // Create an array of totalGrades + 1 elements (the + 1 is to include the maxValue)
    return grades;
}

export function createGradesLegend(minValue, maxValue, totalGrades) {
    const step = (maxValue - minValue) / (totalGrades);
    let grades = Array.from({length: totalGrades + 1}, (_, i) => Number((minValue + i * step).toFixed(1))); // Create an array of totalGrades + 1 elements (the + 1 is to include the maxValue)
    return grades;
}

