import React, { useState, useRef, useEffect, useContext } from 'react';
import ReactSelect from 'react-select';
import { components } from 'react-select';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { lighten } from 'polished';
import styles from './RadarChart.module.css';
import MASContext from '../context/MASContext';
import IndicatorsContext from '../context/IndicatorsContext';
import { getRadarData } from '../data/APIdata';
import MediaQueryContext from '../context/MediaQueryContext';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

const Option = props => {
    return (
      <components.Option {...props}>
        <div style={{ textAlign: 'center' }}>
          {props.label}
        </div>
      </components.Option>
    );
};


const RadarChart = ({lombardMunicipalities, municipalityOptions, municipalitySelected, istatCode})=> {
    const { MAS, selectedMAS } = useContext(MASContext);
    const { isMobileScreen } = useContext(MediaQueryContext);
    const { allIndicators, selectedIndicator } = useContext(IndicatorsContext);
    const chartRef = useRef(null);

    // const masOptions = MAS.map(mas => ({
    //     mas: mas.name,
    //     indicators: mas.indicators.map(indicator => ({
    //         id: indicator.id,
    //         name: indicator.name
    //     }))
    // }));

    const masOptions = MAS.map(mas => ({
        mas: mas.name,
        indicators: mas.indicators
            .filter(indicator => allIndicators.find(ai => ai.id === indicator.id).territorialScale.COM !== 0)
            .map(indicator => ({
                id: indicator.id,
                name: indicator.name
            }))
    }));

    

    const [selectedMas, setSelectedMas] = useState(masOptions.map(masOption => ({ value: masOption.mas, label: masOption.mas })));
    const [selectedIndicators, setSelectedIndicators] = useState(selectedIndicator);
    const [selectedComune, setSelectedComune] = useState(municipalitySelected);
    const [selectedSecondComune, setSelectedSecondComune] = useState("Scegli un comune");
    const [secondIstatCode, setSecondIstatCode] = useState(null);
    const [radarData, setRadarData] = useState(null);
    const [secondRadarData, setSecondRadarData] = useState(null);
    

    //setRadarData(prevData => [...prevData, newData]);

    useEffect(() => {
        async function loadRadarData() {
          try {
            // const municipalityGeoJson = await getGeoJson();
            // console.log(municipalityGeoJson);
            const data = await getRadarData(istatCode);
            setRadarData(data);
            
          } catch (error) {
            console.error('Failed to fetch data:', error);
          }
        }
    
        loadRadarData();
      }, [istatCode, municipalitySelected]);

    useEffect(() => {
    async function loadSecondRadarData(istatCode) {
        try {
        // const municipalityGeoJson = await getGeoJson();
        // console.log(municipalityGeoJson);
        // console.log("Second ISTAT CODE: ", secondIstatCode);
        const data = await getRadarData(secondIstatCode);
        setSecondRadarData(data);
        
        // console.log("Second Radar Data: ", data);
        
        } catch (error) {
        console.error('Failed to fetch data:', error);
        }
    }

    loadSecondRadarData();
    }, [secondIstatCode, selectedSecondComune]);

    const handleSecondComuneChange = (selectedOption) => {
        setSelectedSecondComune(selectedOption.value);
        setSecondIstatCode(selectedOption.istatCode);
    };

    const handleIndicatorChange = (selectedOptions) => {
        setSelectedIndicators(selectedOptions || []);
    };

    const handleMasChange = (selectedOptions) => {
        setSelectedMas(selectedOptions || []);
    };

    const findMasColor = (masName) => {
        const mas = MAS.find(m => m.name === masName);
        return mas ? mas.color : null;
    };

    const truncateString = (str, num) => {
        if (str.length <= num) {
          return str;
        }
        return str.slice(0, num) + '...';
    };

    const createShortLabels = (str) => {
        if (str.length <= 20) { // If the string has 18 or fewer characters
            return str; // Return the original string
        }
        return str.split(' ') // Split the string into words
            .filter(word => word.length >= 4) // Filter out words with less than 5 letters
            .map(word => {
                if (word.includes('(')) { // If the word contains a parenthesis
                    word = word.slice(word.indexOf(')') + 1).trim(); // Cut off everything from the start to the closing parenthesis and trim any leading spaces
                }
                if (word.includes("'")) { // If the word contains an apostrophe
                    word = word.slice(word.indexOf("'") + 1); // Cut off everything from the start to the apostrophe
                }
                
                const numberMatch = word.match(/\d+\.?\d*/); // Match a number in the word
                if (numberMatch) { // If a number was found
                    let num = parseFloat(numberMatch[0].replace('.', '')); // Remove the period and convert the number
                    if (num >= 1000) { // If the number is 1000 or more
                        word = word.replace(numberMatch[0], (num / 1000) + 'k'); // Replace the number in the word with the '10k' format
                    }
                }
                return word.length > 5 ? word.slice(0, 4) + '.' : word; // Truncate words with more than 5 letters and add a dot
            })
            .join(' '); // Join the words back into a string
    };

    const indicatorLabels = masOptions.flatMap(masOption => 
        masOption.indicators.map(indicator => ({
            id: indicator.id,
            shortLabel: truncateString(indicator.name, 17),
            fullLabel: indicator.name
        }))
    );

    // const filteredIndicators = allIndicators.filter(indicator => indicator.territorialScale.COM !== 0);

    // console.log("Filtered Indicators: ", filteredIndicators);

    const groupedMasOptions = masOptions.flatMap(masOption => ([
        { label: masOption.mas, value: masOption.mas },
        ...masOption.indicators.map(indicator => ({ label: indicator.name, value: indicator.id, isDisabled: true })),
    ]));
    
    

    // First, create a set of indicators that have null values
    const nullIndicators = new Set();
        selectedMas.forEach(option => {
        const masOption = masOptions.find(masOption => masOption.mas === option.value);
        masOption.indicators.forEach(indicator => {
            if (indicator.value === null) {
            nullIndicators.add(indicator.name);
            }
        });
    });

    // const fullIndicatorNames = selectedMas.flatMap(option => {
    //     const masOption = masOptions.find(masOption => masOption.mas === option.value);
    //     return masOption.indicators.filter(indicator => !nullIndicators.has(indicator.name)).map(indicator => indicator.name);
    // });

    const radarChartData = {
        labels: selectedMas.flatMap(option => {
            const masOption = masOptions.find(masOption => masOption.mas === option.value);
            return masOption.indicators.filter(indicator => !nullIndicators.has(indicator.name)).map(indicator => isMobileScreen ? indicator.id : createShortLabels(indicator.name));
        }),
        // labels: selectedMas.flatMap(option => {
        //     const masOption = masOptions.find(masOption => masOption.mas === option.value);
        //     return masOption.indicators.filter(indicator => !nullIndicators.has(indicator.name)).map(indicator => indicator.id);
        // }),
        // labels: selectedMas.flatMap(option => {
        //     const masOption = masOptions.find(masOption => masOption.mas === option.value);
        //     return masOption.indicators.filter(indicator => !nullIndicators.has(indicator.name)).map(indicator => truncateString(indicator.name, 17));
        // }),
        fullLabels: selectedMas.flatMap(option => {
            const masOption = masOptions.find(masOption => masOption.mas === option.value);
            return masOption.indicators.filter(indicator => !nullIndicators.has(indicator.name)).map(indicator => indicator.name);
        }),
        datasets: [
            {
                label: selectedComune,
                data: selectedMas.flatMap(option => {
                    const masOption = masOptions.find(masOption => masOption.mas === option.value);
                    return masOption.indicators.filter(indicator => !nullIndicators.has(indicator.name)).map(indicator => {
                        if (radarData && radarData[indicator.id]) {
                            return radarData[indicator.id].normalizedValue ?? null;
                        }
                        return null;
                    });
                }),
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
            selectedSecondComune !== "Scegli un comune" && {
                label: selectedSecondComune,
                data: selectedMas.flatMap(option => {
                    const masOption = masOptions.find(masOption => masOption.mas === option.value);
                    return masOption.indicators.filter(indicator => !nullIndicators.has(indicator.name)).map(indicator => {
                        if (secondRadarData && secondRadarData[indicator.id]) {
                            return secondRadarData[indicator.id].normalizedValue ?? null;
                        }
                        return null;
                    });
                }),
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
        ].filter(Boolean),
    };

    const radarOptions = {
        scales: {
            r: {
                min: 0,
                ticks: {
                    display: false
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    // title: function(tooltipItem) {
                    //     const index = radarChartData.labels.indexOf(tooltipItem[0].label);
                    //     return radarChartData.fullLabels[index];
                    // }
                    // title: function(tooltipItem) {
                    //     const id = tooltipItem[0].label;
                    //     const indicator = indicatorLabels.find(indicator => indicator.id === id);
                    //     return indicator ? indicator.fullLabel : id; // Use fullLabel for the tooltip
                    // }
                    title: function(tooltipItem) {
                        const shortedLabel = tooltipItem[0].label;
                        const indicator = isMobileScreen 
                            ? indicatorLabels.find(indicator => indicator.id === shortedLabel)
                            : indicatorLabels.find(indicator => createShortLabels(indicator.fullLabel) === shortedLabel);
                        return indicator ? indicator.fullLabel : shortedLabel; // Use fullLabel for the tooltip
                    }
                }
            },
        }
    };

    // on the center of the radar double value in the tooltip

    return (
        <>
        <div className={styles.radarSelectContainer}>
            <div className={styles.selectGroupMenu}>
                <div className={styles.selectMunicipality}>
                    <ReactSelect
                        value={municipalityOptions.find(municipalityOption => municipalityOption.value === selectedSecondComune)}
                        onChange={(selectedOption) => handleSecondComuneChange(selectedOption)}
                        options={[{ value: 'Scegli un comune', label: 'Nessuna selezione' }, ...municipalityOptions.filter(option => option.value !== municipalitySelected )]}
                        placeholder="Scegli un comune"
                        styles={{
                        placeholder: (provided) => ({
                            ...provided,
                            fontSize: '0.7em' // adjust the size as needed
                        })
                        }}
                    />
                </div>
                <div className={styles.selectMultipleMAS}>
                    <ReactSelect
                        components={{ Option }}
                        options={groupedMasOptions}
                        isMulti
                        value={selectedMas}
                        onChange={handleMasChange}
                        hideSelectedOptions={false}
                        placeholder="Seleziona i MAS"
                        styles={{
                            placeholder: (provided) => ({
                            ...provided,
                            fontSize: '0.8em' // adjust the size as needed
                            }),
                            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                            const color = findMasColor(data.label);
                                return {
                                    ...styles,
                                    backgroundColor: isDisabled
                                    ? color ? lighten(0.4, color) : null
                                    : isSelected
                                    ? color
                                    : isFocused
                                    ? lighten(0.2, color)
                                    : null,
                                    color: isDisabled
                                    ? '#ccc'
                                    : styles.color,
                                    cursor: isDisabled ? 'not-allowed' : 'default',
                                };
                            },
                        }}
                    />
                </div>
            </div>
        </div>
            
        <div className={styles.radarChartContainer}>
            <Radar
                ref={chartRef}
                data={radarChartData}
                options={radarOptions}
            />
        </div>     
        </>
    );             
};

export default RadarChart;