import React, { useState, useEffect, useContext } from 'react';
import ReactSelect from 'react-select';
import styles from './Dashboard.module.css';
import LineChart from './LineChart';
import Map from './Map';
import Table from './Table';
import RadarChart from './RadarChart';
import MediaQueryContext from '../context/MediaQueryContext'; 
import MASContext  from '../context/MASContext';
import IndicatorsContext from '../context/IndicatorsContext';
import { defaultPositions } from '../data/mapPositions';
// import { getGeoJson } from '../data/APIdata';
import { getIndicatorData } from '../data/APIdata';
import { getGeoJson } from '../data/APIdata';


const Dashboard = () => {
  const { isTabletScreen, isMobileScreen } = useContext(MediaQueryContext);
  const { MAS, selectedMAS, colorSelectedMAS } = useContext(MASContext);
  const { indicators, selectedIndicator, selectedIndicatorInfo, handleIndicatorChange } = useContext(IndicatorsContext);
  const [selectedLocation, setSelectedLocation] = useState('LombardyRegion');
  const [selectedComune, setSelectedComune] = useState('');
  const [istatCode, setIstatCode] = useState('03'); //default istat code for Lombardy
  const [positionSelectedComune, setPositionSelectedComune] = useState(defaultPositions.Milan.centre);
  const [selectedType, setSelectedType] = useState('RegionMap');
  const [selectedMunicipalityType, setSelectedMunicipalityType] = useState('MunicipalityMap');
  const [indicatorData, setIndicatorData] = useState(null);
  const [geojsonData, setGeojsonData] = useState(null);
  const [lombardMunicipalities, setLombardMunicipalities] = useState([]);
  const [municipalityOptions, setMunicipalityOptions] = useState([]);
  //for indicators:
  const [imageSrc, setImageSrc] = useState('');
  const [title, setTitle] = useState('');
  const [hoveredIndicator, setHoveredIndicator] = useState(null);

  useEffect(() => {
    const geoJson = getGeoJson();
    setGeojsonData(geoJson);
  }, []);

  useEffect(() => {
    if (geojsonData) {
      let municipalities = geojsonData.features
        .filter(feature => feature.properties.istatCode.length === 6)
        .map(feature => {
          let [longitude, latitude] = feature.properties.centroid.split(',').map(Number);
          return {
            name: feature.properties.title,
            codiceISTAT: feature.properties.istatCode,
            position: [latitude, longitude]
          };
        });

      setLombardMunicipalities(municipalities);

      const options = municipalities.map( municipality => ({ 
        value: municipality.name, 
        label: municipality.name, 
        istatCode: municipality.codiceISTAT 
      }));

      setMunicipalityOptions(options);
    }
  }, [geojsonData]);

  useEffect(() => {
    async function loadDashboardData() {
      try {
        if (selectedIndicator) {
          const data = await getIndicatorData(selectedIndicator.id, istatCode);
          // console.log("Data: ", data);
          setIndicatorData(data);
  
          // Check if dataCom is 0 and adjust the view accordingly
          if (selectedIndicatorInfo.dataCom === 0) {
            setSelectedType('Diagram');
            handleLocationChange('LombardyRegion');
          }

          if (selectedIndicatorInfo.dataReg === 0) {
            if (selectedLocation === 'LombardyRegion') setSelectedType('RegionMap');
            else setSelectedMunicipalityType('MunicipalityMap');
          }
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    }
  
    loadDashboardData();
  }, [selectedIndicator, istatCode, selectedLocation]);

  const handleLocationChange = (location) => {
    setSelectedLocation(location);
  };

  const handleSelectMunicipalityInput = (selectedOption) => {
    setSelectedComune(selectedOption.value);
    handleLocationChange(selectedOption.value);
    setPositionSelectedComune(lombardMunicipalities.find(municipality => municipality.name === selectedOption.value).position);
    setIstatCode(lombardMunicipalities.find(municipality => municipality.name === selectedOption.value).codiceISTAT);
  };

  const handleMunicipalitySubmit = (event) => {
    event.preventDefault();
    setSelectedLocation(selectedComune);
  };

  const handleTypeChange = (type) => {
    if (type.includes('Municipality')) {
        setSelectedMunicipalityType(type);
    } else {
        setSelectedType(type);
    }
  };

  let tableData = [];
  // console.log("ragionData: ", indicatorData);
  for (let regionCode in indicatorData) {
    if (regionCode.length == 3) continue; // Skip the province data (not used in the dashboard)
    let regionData = indicatorData[regionCode];
    
    for (let year in regionData.data) {
      let value = regionData.data[year];
      let existingRow = tableData.find(row => row.year === year);
      if (existingRow) {
        existingRow[regionData.realname] = value;
      } else {
        tableData.push({
          year: year,
          [regionData.realname]: value
        });
      }
    }
  }
  
  tableData.sort((a, b) => a.year - b.year);

  // console.log("tableData: ", tableData);
  
  let years = tableData.map(item => item.year);

  // console.log("years: ", years);

  let locations = ['Italia', 'Lombardia'];
  if(selectedIndicatorInfo.dataReg === 0) locations = [];

  // console.log("locations: ", locations);

  // const renderIndicators = (indicators) => {
  //   if (!Array.isArray(indicators)) {
  //     return null;
  //   }
  //   return indicators.map((indicator, index) => {
  //     const isSelected = selectedIndicator.name === indicator.name;
  //     const isHovered = hoveredIndicator === indicator.name;
  //     return (
  //       <div
  //         className={`${styles.indicator} ${isSelected ? styles.selected : ''}`}
  //         key={index}
  //         onClick={() => handleIndicatorChange(indicator.id)}
  //         onMouseEnter={() => setHoveredIndicator(indicator.name)}
  //         onMouseLeave={() => setHoveredIndicator(null)}
  //         style={{
  //           backgroundColor: isSelected || isHovered ? colorSelectedMAS : 'initial',
  //           color: isSelected || isHovered ? '#ffffff' : 'initial',
  //           fontWeight: isSelected ? 'bold' : 'normal'
  //         }}
  //       >
  //         {indicator.name}
  //       </div>
  //     );
  //   });
  // };

  useEffect(() => {
    if (MAS[selectedMAS - 1]) {
      setImageSrc(MAS[selectedMAS - 1].icon);
      setTitle(MAS[selectedMAS - 1].id); //or .description
    }
  }, [selectedMAS, MAS]);

  const renderIndicators = (indicators) => {
    if (!Array.isArray(indicators)) {
      return null;
    }
  
    const options = indicators.map(indicator => ({
      value: indicator.id,
      label: indicator.name
    }));
  
    const selectedOption = options.find(option => option.value === selectedIndicator.id);
  
    return (
      <ReactSelect 
        className={styles.select}
        options={options}
        value={selectedOption}
        onChange={option => handleIndicatorChange(option.value)}
        data-umami-event = {`Indicator-${selectedOption}`}
        isSearchable={false}
      />
    );
  };

  

  

  return (
    <div className={isMobileScreen ? styles.dashboard_mobile : styles.dashboard}>
        { isMobileScreen && ( 
          <>
          <div className={styles.row}>
            <div className={styles.image_container} style={{backgroundColor: colorSelectedMAS}}>
              <img src={imageSrc} alt="MAS" className={styles.image} />
              {/* <h5 className={styles.title}>{title}</h5> */}
            </div>
            
            <div className={styles.indicators_container}>{renderIndicators(indicators)}</div>
          </div>
          </>
        )}
      <div className={isMobileScreen ? styles.topButtonsMobile : styles.topButtons}>
        <div 
          className={`${styles.tab} ${selectedLocation === 'LombardyRegion' ? styles.activeTab : ''}`}
          style={{ borderBottomColor: selectedLocation !== 'LombardyRegion' ? colorSelectedMAS : '#000' }}
          >
          <button
            className={`${isMobileScreen ? styles.topButtonMobile : styles.topButton} 
              ${isMobileScreen 
                ? (selectedLocation === 'LombardyRegion' ? styles.activeMobile : styles.inactiveMobile)
                : (selectedLocation === 'LombardyRegion' ? styles.active : styles.inactive)
              }`}
            onClick={() => handleLocationChange('LombardyRegion')}
            style={{ backgroundColor: selectedLocation === 'LombardyRegion' ? colorSelectedMAS : 'initial' }}
          >
            Regione Lombardia
          </button>
        </div>

        {selectedIndicatorInfo && selectedIndicatorInfo.dataCom !== 0 ?  (
          <div 
            className={`${styles.tab} ${selectedLocation !== 'LombardyRegion' ? styles.activeTab : ''}`}
            style={{ borderBottomColor: selectedLocation === 'LombardyRegion' ? colorSelectedMAS : '#000' }}
            >
            <button 
              className={`${isMobileScreen ? styles.topButtonSelectionMobile : styles.topButtonSelection} 
                ${isMobileScreen 
                  ? (selectedLocation !== 'LombardyRegion' ? styles.activeMobile : styles.inactiveMobile)
                  : (selectedLocation !== 'LombardyRegion' ? styles.active : styles.inactive)
                }`}
              style={{ backgroundColor: selectedLocation !== 'LombardyRegion' ? colorSelectedMAS : 'initial' }}
              >
              <div className={`${isMobileScreen ? styles.comuneInputMobile : styles.comuneInput}`}>
                <span>Comune di:</span>
                <ReactSelect
                  options={municipalityOptions}
                  value={selectedLocation === 'LombardyRegion' ? null : (selectedComune ? { value: selectedComune, label: selectedComune } : null)}
                  onChange={handleSelectMunicipalityInput}
                  data-umami-event = {`Municipality-${istatCode}`}
                  styles={{
                    menu: provided => ({ ...provided, zIndex: 9999, fontSize: '1em' }),
                    option: (provided, state) => ({
                      ...provided,
                      color: state.isSelected ? 'white' : 'black',
                      backgroundColor: state.isSelected ? colorSelectedMAS : null,
                      fontWeight: 'normal',
                      fontSize: '0.8em'
                    }),
                    singleValue: (provided) => ({ ...provided, fontSize: '0.75em' }),
                  }}
                />
              </div>
            </button>
          </div>
        ) : null}
      </div>
      
      {selectedLocation === 'LombardyRegion' ? (
        <div className={isMobileScreen ? styles.bottomButtonsMobile : styles.bottomButtons}>
          {selectedIndicatorInfo && selectedIndicatorInfo.dataCom !== 0 ? ( 
            <button 
              className={`${isMobileScreen ? styles.buttonMobile : styles.button} ${selectedType === 'RegionMap' ? styles.activeButton : ''}`} 
              onClick={() => handleTypeChange('RegionMap')}
              style={{ backgroundColor: selectedType === 'RegionMap' ? colorSelectedMAS : 'initial' }}
              data-umami-event = {`Map`}
              data-umami-event-mas={selectedMAS ? `MAS${selectedMAS}` : undefined}
              data-umami-event-indicator={selectedIndicator && selectedIndicator.id ? `Indicator-${selectedIndicator.id}` : undefined}
            >
              MAPPA REGIONE
            </button> 
          ) : null }
          {selectedIndicatorInfo && selectedIndicatorInfo.dataReg !== 0 ? (
            <>
              <button 
                className={`${isMobileScreen ? styles.buttonMobile : styles.button} ${selectedType === 'Diagram' ? styles.activeButton : ''}`} 
                onClick={() => handleTypeChange('Diagram')}
                style={{ backgroundColor: selectedType === 'Diagram' ? colorSelectedMAS : 'initial' }}
                data-umami-event = {`Chart`}
                data-umami-event-mas={selectedMAS ? `MAS${selectedMAS}` : undefined}
                data-umami-event-indicator={selectedIndicator && selectedIndicator.id ? `Indicator-${selectedIndicator.id}` : undefined}
              >
                DIAGRAMMA
              </button>
              <button 
                className={`${isMobileScreen ? styles.buttonMobile : styles.button} ${selectedType === 'Table' ? styles.activeButton : ''}`} 
                onClick={() => handleTypeChange('Table')}
                style={{ backgroundColor: selectedType === 'Table' ? colorSelectedMAS : 'initial' }}
                data-umami-event = {`table`}
                data-umami-event-mas={selectedMAS ? `MAS${selectedMAS}` : undefined}
                data-umami-event-indicator={selectedIndicator && selectedIndicator.id ? `Indicator-${selectedIndicator.id}` : undefined}
              >
                TABELLA
              </button>
            </>
          ) : null }
        </div>
      ) : selectedIndicatorInfo && selectedIndicatorInfo.dataCom !== 0 ? (
        <div className={isMobileScreen ? styles.bottomButtonsMobile : styles.bottomButtons}>
          <button 
            className={`${isMobileScreen ? styles.buttonMobile : styles.button} ${selectedMunicipalityType === 'MunicipalityMap' ? styles.activeButton : ''}`} 
            onClick={() => handleTypeChange('MunicipalityMap')}
            style={{ backgroundColor: selectedMunicipalityType === 'MunicipalityMap' ? colorSelectedMAS : 'initial' }}
            data-umami-event = {`Map`}
            data-umami-event-mas={selectedMAS ? `MAS${selectedMAS}` : undefined}
            data-umami-event-indicator={selectedIndicator && selectedIndicator.id ? `Indicator-${selectedIndicator.id}` : undefined}
            data-umami-event-municipality = {istatCode ? `Municipality-${istatCode}`: undefined}
          >
            MAPPA COMUNE
          </button>
          <button 
            className={`${isMobileScreen ? styles.buttonMobile : styles.button} ${selectedMunicipalityType === 'MunicipalityDiagram' ? styles.activeButton : ''}`} 
            onClick={() => handleTypeChange('MunicipalityDiagram')}
            style={{ backgroundColor: selectedMunicipalityType === 'MunicipalityDiagram' ? colorSelectedMAS : 'initial' }}
            data-umami-event = {`Chart`}
            data-umami-event-mas={selectedMAS ? `MAS${selectedMAS}` : undefined}
            data-umami-event-indicator={selectedIndicator && selectedIndicator.id ? `Indicator-${selectedIndicator.id}` : undefined}
            data-umami-event-municipality = {istatCode ? `Municipality-${istatCode}`: undefined}
          >
            DIAGRAMMA
          </button>
          <button 
            className={`${isMobileScreen ? styles.buttonMobile : styles.button} ${selectedMunicipalityType === 'MunicipalityRadar' ? styles.activeButton : ''}`} 
            onClick={() => handleTypeChange('MunicipalityRadar')}
            style={{ backgroundColor: selectedMunicipalityType === 'MunicipalityRadar' ? colorSelectedMAS : 'initial' }}
            data-umami-event = {`Radar`}
            data-umami-event-mas={selectedMAS ? `MAS${selectedMAS}` : undefined}
            data-umami-event-indicator={selectedIndicator && selectedIndicator.id ? `Indicator-${selectedIndicator.id}` : undefined}
            data-umami-event-municipality = {istatCode ? `Municipality-${istatCode}`: undefined}
          >
            RADAR
          </button>
          <button 
            className={`${isMobileScreen ? styles.buttonMobile : styles.button} ${selectedMunicipalityType === 'MunicipalityTable' ? styles.activeButton : ''}`} 
            onClick={() => handleTypeChange('MunicipalityTable')}
            style={{ backgroundColor: selectedMunicipalityType === 'MunicipalityTable' ? colorSelectedMAS : 'initial' }}
            data-umami-event = {`Table`}
            data-umami-event-mas={selectedMAS ? `MAS${selectedMAS}` : undefined}
            data-umami-event-indicator={selectedIndicator && selectedIndicator.id ? `Indicator-${selectedIndicator.id}` : undefined}
            data-umami-event-municipality = {istatCode ? `Municipality-${istatCode}`: undefined}
          >
            TABELLA
          </button>
        </div>
      ) : null}
      {selectedIndicatorInfo && selectedIndicatorInfo.dataCom !== 0 ? (
        <>
          {selectedType === 'RegionMap' && selectedLocation === 'LombardyRegion' && (
            <div className={styles.visualization}>
              <Map mapId="regionMap" center={defaultPositions.Lombardy.centre} geojsonData={geojsonData} zoom={defaultPositions.Lombardy.zoom}  years={years}/>
            </div>
          )}
        </>
      ) : null}
      {selectedType === 'Diagram' && selectedLocation === 'LombardyRegion' && (
        <div className={styles.visualization}>
          <LineChart indicatorData={indicatorData} tableData={tableData} locations={locations}/>
        </div>
      )}
      {selectedType === 'Table' && selectedLocation === 'LombardyRegion' && (
        <div className={styles.visualization}>
          <Table istatCode={istatCode} tableData={tableData} years={years} locations={locations}/>
        </div>
      )}
      {selectedIndicatorInfo && selectedIndicatorInfo.dataCom !== 0 ? (
        <>
          {selectedMunicipalityType === 'MunicipalityMap' && selectedLocation !== 'LombardyRegion' && (
            <div className={styles.visualization}>
              <Map mapId="municipalityMap" center={positionSelectedComune} geojsonData={geojsonData} zoom={11} municipalitySelected={selectedComune} years={years}/>
            </div>
          )}
          {selectedMunicipalityType === 'MunicipalityDiagram' && selectedLocation !== 'LombardyRegion' && (
            <div className={styles.visualization}>
              <LineChart municipalitySelected={selectedComune} tableData={tableData} locations={locations}/>
            </div>
          )}
          {selectedMunicipalityType === 'MunicipalityRadar' && selectedLocation !== 'LombardyRegion' && (
            <div className={styles.visualization}>
              <RadarChart lombardMunicipalities={lombardMunicipalities} municipalityOptions={municipalityOptions} municipalitySelected={selectedComune} istatCode={istatCode}/>
            </div>
          )}
          {selectedMunicipalityType === 'MunicipalityTable' && selectedLocation !== 'LombardyRegion' && (
            <div className={styles.visualization}>
              <Table municipalitySelected={selectedComune} istatCode={istatCode} tableData={tableData} years={years} locations={locations}/>
            </div>
          )}
        </>
      ) : null }
      
    </div>
  );
};

export default Dashboard;
