import he from 'he';

export function decodeStringsInObject(obj) {
    for (let key in obj) {
        if (typeof obj[key] === 'string') {
            obj[key] = he.decode(obj[key]);
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            decodeStringsInObject(obj[key]);
        }
    }
}