import React, { useContext, useEffect } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from 'recharts';
import styles from './LineChart.module.css';
import IndicatorsContext from '../context/IndicatorsContext';
import MediaQueryContext from '../context/MediaQueryContext';
import MASContext from '../context/MASContext';

const LineCharts = ({ municipalitySelected, tableData, locations }) => {

  const { indicators, selectedIndicator, selectedIndicatorInfo } = useContext(IndicatorsContext);
  const { MAS, selectedMAS, colorSelectedMAS } = useContext(MASContext);
  const { isMobileScreen, isTabletScreen } = useContext(MediaQueryContext);
  const [responsiveWidth, setResponsiveWidth] = React.useState(700);
  const [responsiveHeight, setResponsiveHeight] = React.useState(400);

  useEffect(() => {
    const handleResize = () => {
      if (isMobileScreen) {
        setResponsiveWidth(window.innerWidth-16); //16px is 1em in pixel 
        setResponsiveHeight(350);
      }
      else if (isTabletScreen) setResponsiveWidth(550);
    };
  
    // Imposta la larghezza iniziale
    handleResize();
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobileScreen, isTabletScreen]);
    


  let activeMunicipality = municipalitySelected || '';

  if (municipalitySelected) {
    locations.push(municipalitySelected);
  }
  

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#ffff', padding: '5px', border: '1px solid #cccc' }}>
          <p className="label" style={{ fontWeight: 'bold', textAlign: 'center' }}>{`Anno: ${label}`}</p>
          {payload.map((item, index) => (
            <p key={`item-${index}`} style={{ color: item.color }}>
              <span style={{ color: item.color }}>{`${item.name}:`}</span>
              <span style={{ color: 'black' }}>{`${item.value} ${selectedIndicatorInfo.unitMeasure}`}</span>
            </p>
          ))}
        </div>
      );
    }
  
    return null;
  };

  return (
    <div className={styles.lineChart}>
      <LineChart width={responsiveWidth} height={responsiveHeight} data={tableData}>
        <CartesianGrid stroke="#ccc" />
        <XAxis dataKey="year" />
        <YAxis />
        <Tooltip content={<CustomTooltip />}/>
        <Legend />
        {selectedIndicatorInfo.dataReg != 0 && <Line type="monotone" dataKey="Italia" stroke="#085AC2" connectNulls={false} />}
        {selectedIndicatorInfo.dataReg != 0 && <Line type="monotone" dataKey="Lombardia" stroke="#24B24B" strokeWidth={activeMunicipality ? 1 : 2.5} connectNulls={false} />}
        {activeMunicipality && <Line type="monotone" dataKey={activeMunicipality} stroke={colorSelectedMAS} strokeWidth={2.5} connectNulls={false} />}
      </LineChart>
    </div>
  );
};

export default LineCharts;
