import React, { useContext } from "react";
import styles from "./Footer.module.css";
import configData from "../config.json";
import coloureeLogo from "../assets/Logo_Colouree.svg";
import pdfFile from "../files/GuidaCruscottoSviluppoSostenibile.pdf"; 
import MediaQueryContext from "../context/MediaQueryContext";

function Footer() {
  const { isMobileScreen } = useContext(MediaQueryContext);
  const email1 = `mailto: ${configData.FOOTER.EMAIL_1}`;
  const logo1 = configData.FOOTER.ICON_1;
  const pdfUrl = "../files/GuidaCruscottoSviluppoSostenibile.pdf";
  return (
    <div className={styles.footer} id="footer">
      ANCI Lombardia | Via Rovello, 2 20121 Milano, MI | centralino
      02-72629601 | &nbsp;
      <a href={email1} className={styles.mail_link}>
        PEC - Posta Elettronica Certificata
      </a>
      &nbsp;|&nbsp; C. fiscale 80160390151 &nbsp;| P. Iva 04875270961 <br /> powered by
      <a
        href={logo1}
        className={styles.logo_link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={coloureeLogo}
          className={styles.logo_icon}
          alt="Colouree logo"
        />
      </a>
      {!isMobileScreen && 
        <a
          href={pdfFile}
          download="GUIDA_CruscottoIndicatoriSviluppoSostenibile.pdf"
          className={styles.download_link}
          data-umami-event = {`DownloadGuide`}
        >
          Scarica la guida
        </a>
      }
    </div>
  );
}

export default Footer;
