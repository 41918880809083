import { useRef, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from 'react-router-dom';
import styles from "./LandingContent.module.css"
import wheelImage from "../assets/landing/wheelSdg.webp";
import backgroundImageSdg from "../assets/landing/backgroundSdg.jpeg";
import handleLayout from "../utils/handleFrontPageLayout";

function LandingContent(props) {
  const imageRef = useRef();
  const isMobile = useMediaQuery({ maxWidth: "800px" });
  const navigate = useNavigate();

  // adjust layout to cover a whole screen
  setTimeout(() => {
    handleLayout();
  }, 500);

  //used to prevent scrolling on the body when the wheel is visible (only for the landing page)
  useEffect(() => {
    // Save the previous overflow style property
    const prevOverflow = document.body.style.overflow;
  
    // Set the overflow style property to hidden to prevent scrolling on the body
    document.body.style.overflow = 'hidden';
  
    // Reset the overflow style property on body after component unmount
    return () => {
      document.body.style.overflow = prevOverflow;
    };
  }, []);

  function onClickHandler() {
    imageRef.current.animate(
      [
        // keyframes

        { transform: "rotate(0)" },
        { transform: "rotate(360deg)" },
      ],
      // timing options
      {
        duration: 3000,
      }
    );
    // we wait 1.5 second and we toggle the visibility of wheel
    setTimeout(() => {
      navigate('/cruscotto');
    }, 1500);
  }

  return (
    <div className={styles.container} style={{backgroundImage: `url(${backgroundImageSdg})`}}>
      <div className={styles.wrapper} id="wheel_wrapper">
        <div className={styles.img_text_container}>
          <img
            ref={imageRef}
            className={styles.goalIcon}
            src={wheelImage}
            alt="Cruscotto degli indicatori di sviluppo sostenibile"
            onClick={onClickHandler}
          />
        </div>
        <div className={styles.textContainer}>
          <h1 className={styles.title}>Cruscotto degli indicatori di sviluppo sostenibile</h1>
        </div>
      </div>    
    </div>
  );
}
export default LandingContent;