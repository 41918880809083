import React, { useCallback, useState, useEffect, useRef, useContext } from 'react';
import { GeoJSON } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import styles from './Map.module.css';
import Spinner from 'react-bootstrap/Spinner'; 
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import { getColor } from '../utils/colors';
import { getMAPData } from '../data/APIdata';
import MASContext from '../context/MASContext';
import IndicatorsContext from '../context/IndicatorsContext';
import MediaQueryContext from '../context/MediaQueryContext';
import { createGrades } from '../utils/legendGrades';
import { useDebounce } from '../utils/debounce';
import { SetViewOnRender } from '../utils/mapUtils';
import MapControls from './MapControls';
import MapLegend from './MapLegend';

// function MapInstanceProvider({ setMap }) {
//   const mapInstance = useMap();
//   useEffect(() => {
//     setMap(mapInstance);
//   }, [mapInstance, setMap]);

//   return null;
// }

export default function Map({ mapId, center, zoom, municipalitySelected, geojsonData, years}) {
  const { isMobileScreen } = useContext(MediaQueryContext);
  const { MAS, selectedMAS, colorSelectedMAS } = useContext(MASContext);
  const { selectedIndicator, selectedIndicatorInfo, minYear, maxYear, minValue, maxValue } = useContext(IndicatorsContext);
  const [isLoading, setIsLoading] = useState(true);
  const [map, setMap] = useState(null);
  const [year, setYear] = useState(2021); // Default year
  const debouncedYear = useDebounce(year, 500);
  const [legend, setLegend] = useState(null);
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [MAPData, setMAPData] = useState(null);
  const [defaultData, setDefaultData] = useState(null); // Default data for GeoJSON
  const geojsonRef = useRef(null);
  // const legendRef = useRef();


  let waitForceUpdate = false; // Used to force the update of the map after the year changes


  //this will run only when selectedIndicator changes and will force the update of the year
  useEffect(() => {
    waitForceUpdate = true;
    const originalYear = maxYear;
  
    // Use a setTimeout to set the year to the maxValue of the new indicator
    setTimeout(() => {
      setYear(originalYear); // Set year back to the original value
    }, 500); // Same delay as the debounce

    waitForceUpdate = false;
    
  }, [selectedIndicator, minYear, selectedMAS]); 

  // Modify it to municipality view having value extriming from the range ( good, bad and simil to me)


  useEffect(() => {
    async function loadMAPData() {
      setIsLoading(true);
      try {
        if (selectedIndicator) {
          if (!waitForceUpdate) {
            
            // console.log('minYear:', minYear);
            // console.log('maxYear:', maxYear);

            // console.log('debouncedYear:', debouncedYear);
          
            const data = await getMAPData(selectedIndicator.id, debouncedYear);
            // console.log("MAPData: ", data);
            if (data) {
              setMAPData(data);
            }
            // else {
            //   console.error('Invalid GeoJSON data:', data);
            // }
          }
        }  
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setIsLoading(false);
      }
    }
  
    loadMAPData();
  }, [debouncedYear, selectedIndicator]); //debouncedYear, selectedIndicator, maxYear, minYear


  const handleYearChange = ({ x }) => {
    // Convert the year to a number
    const selectedYear = Number(x);
  
    // Check if the selected year is in the years array
    if(years.length === 0) setYear(selectedYear);
    else{
      if (years.includes(selectedYear)) {
        setYear(selectedYear);
      } else {
        // If the selected year is not in the years array, find the closest year that is
        const closestYear = years.reduce((prev, curr) =>
          Math.abs(curr - selectedYear) < Math.abs(prev - selectedYear) ? curr : prev
        );
    
        setYear(closestYear);
      }
    }
  };

  const onEachFeature = useCallback((feature, layer) => {
    // Find the corresponding object in the data array
    let mapDataItem = null;
    if (MAPData) {

      // if (selectedLayer) {
      //       selectedLayer.setStyle({
      //         weight: 0.5,
      //       });
      //       setSelectedLayer(null);
      // }
      mapDataItem = MAPData.data.find(item => item.areaCode === feature.properties.istatCode);



      // If the object exists, use its value
      // Instead, use null
      const value = mapDataItem && mapDataItem.value !== null ? mapDataItem.value : 'N/A';
      // const normalizedValue = mapDataItem && mapDataItem.value !== null ? mapDataItem.normalizedValue : null;
      const istatCodeParent = mapDataItem && mapDataItem.parentAreaCode !== null ? mapDataItem.parentAreaCode : null;
      const parentName = mapDataItem && mapDataItem.parentAreaCodeName !== null ? mapDataItem.parentAreaCodeName : null;


      const totalGrades = municipalitySelected ? 3 : 10;
      const grades = createGrades(minValue, maxValue, totalGrades); 
      

      layer.setStyle({
        fillColor: value != 'N/A' ? getColor(value, municipalitySelected, grades, totalGrades) : 'transparent',
        weight: municipalitySelected === feature.properties.title ? 5 : 0.5, // Increase the weight of the border of the selected municipality
        opacity: value !== 'N/A' ? 1 : 0.5, 
        fillOpacity: value !== 'N/A' ? 0.6 : 0, // i can't use value ? 1 : 0 because 'value' can be 0
        // weight: layer.feature.properties.title === municipalitySelected ? 5 : 0.5,
      });
              

      
      
    
      // Add styled info for the popup
      if (value==='N/A')
        layer.bindPopup(`<div style="text-align: center;"><strong>${feature.properties.title}</strong></div>
                          C.Istat: ${feature.properties.istatCode}<br>In provincia di: ${parentName}<br>C.Istat Provincia: ${istatCodeParent}<br>
                          Valore indicatore: Assenza di dati`); 
      else
        layer.bindPopup(`<div style="text-align: center;"><strong>${feature.properties.title}</strong></div>
                          C.Istat: ${feature.properties.istatCode}<br>In provincia di: ${parentName}<br>C.Istat Provincia: ${istatCodeParent}<br>
                          Valore indicatore: ${value}`); //<br><br><br>Indicatore selezionato: ${selectedIndicator ? selectedIndicator.name : 'N/A'}
    }
  }, [MAPData, MAS, selectedIndicator, municipalitySelected]); //MAPData, MAS, selectedIndicator, municipalitySelected, 
  
  
  // // Add an effect to change the style of the selected layer
  useEffect(() => {
    // If there is a selected layer, reset its style
    if (selectedLayer) {
      selectedLayer.setStyle({
        weight: 0.5,
      });
    }
  
    // Find the layer with the selected municipality and set its style
    if (geojsonRef.current) {
      geojsonRef.current.eachLayer((layer) => {
        if (layer.feature.properties.title === municipalitySelected) {
          layer.setStyle({
            weight: 5,
          });
  
          // Update the selected layer
          setSelectedLayer(layer);
        }
      });
    }
  }, [municipalitySelected]);

  // not reset the map when the year and selected indicator changes
  return (
    <div>
      <MapContainer
        key={selectedIndicator ? `${mapId}-${selectedIndicator.id}` : `${mapId}-DIVOCC`} // id={mapId}
        center={center}
        zoom={zoom}
        className={isMobileScreen ? styles.mapContainerMobile : styles.mapContainer}
        step={1}
        zoomControl={false} 
        whenCreated={(mapInstance) => mapInstance.invalidateSize()}
      >
        {/* <MapInstanceProvider setMap={setMap} /> */}
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png"
        />
        <SetViewOnRender coords={center} />

        {!isLoading && (
          <GeoJSON ref={geojsonRef} key={`${year}-${MAS}-${selectedIndicator}`} data={geojsonData} onEachFeature={onEachFeature}  /> //onEachFeature={onEachFeature}
        )}
        {isLoading && (
          <div style={{ position: 'relative', height: '100%' }}>
            <GeoJSON ref={geojsonRef} key={`${year}-${MAS}`} data={geojsonData} onEachFeature={onEachFeature} style={() => ({ fillOpacity: 0, opacity: 0.2 })}/>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
              <Spinner animation="border" role="status" variant="light">
                <span style={{ fontSize: '2em', fontWeight: 'bold', color: 'white', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Caricamento...</span>
              </Spinner>
            </div>
          </div>
        )}

        {selectedIndicator && (
          <MapLegend key={selectedIndicator.id} legend={legend} setLegend={setLegend} map={map} municipalitySelected={municipalitySelected} selectedIndicator={selectedIndicator} selectedIndicatorInfo={selectedIndicatorInfo} minValue={minValue} maxValue={maxValue} />
        )}
          
        
      </MapContainer>
      {MAPData && (
        <MapControls year={year} minYear={minYear} maxYear={maxYear} handleYearChange={handleYearChange} />
      )}
      
    </div>
  );
};

