import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MediaQueryContext from './context/MediaQueryContext';
import { useMediaQuery } from 'react-responsive';
import { MenuProvider }  from './context/MenuContext';
import { MASProvider } from './context/MASContext';
import { IndicatorsProvider } from './context/IndicatorsContext';

const MediaQueryProvider = ({ children }) => {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletScreen = useMediaQuery({ query: '(max-width: 1224px)' });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 839px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  return (
    <MediaQueryContext.Provider value={{ isDesktopOrLaptop, isBigScreen, isTabletScreen, isMobileScreen, isPortrait, isRetina }}>
      {children}
    </MediaQueryContext.Provider>
  );
};

const root = document.getElementById('root');
createRoot(root).render(
  <React.StrictMode>
    <MediaQueryProvider>
      <MASProvider>
        <IndicatorsProvider>
          <MenuProvider>
            <App />
          </MenuProvider>
        </IndicatorsProvider>
      </MASProvider>
    </MediaQueryProvider>
  </React.StrictMode>
);

// ReactDOM.render(
//   <React.StrictMode>
//     <MediaQueryProvider>
//       <MASProvider>
//         <IndicatorsProvider>
//           <MenuProvider>
//             <App />
//           </MenuProvider>
//         </IndicatorsProvider>
//       </MASProvider>
//     </MediaQueryProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
