import React, { useState, useEffect, useContext } from 'react';
import { getIndicatorsAnalytics } from '../data/APIdata';
import MASContext from './MASContext';


const IndicatorsContext = React.createContext();

export const IndicatorsProvider = ({ children }) => {
    const { MAS, selectedMAS } = useContext(MASContext);
    const [selectedIndicator, setSelectedIndicator] = useState(null);
    const [selectedIndicatorInfo, setSelectedIndicatorInfo] = useState({source: '', description: '', unitMeasure: '', dataCom: '', DataReg: ''});
    const [allIndicators, setAllIndicators] = useState([]); // All indicators
    const [indicators, setIndicators] = useState([]); // All indicators of the selected MAS 
    const [analyticsData, setAnalyticsData] = useState({});
    const [minYear, setMinYear] = useState(null);
    const [maxYear, setMaxYear] = useState(null);
    const [minValue, setMinValue] = useState(null);
    const [maxValue, setMaxValue] = useState(null);

    useEffect(() => {
        setAllIndicators(MAS.map(mas => mas.indicators.map(indicator => ({ ...indicator })) ).flat());
        
    }, [MAS]);

    useEffect(() => {
        if (MAS && MAS.length >= selectedMAS && selectedMAS >= 0) {
            const defaultIndicator = MAS[selectedMAS - 1].indicators[0];
            setSelectedIndicator(defaultIndicator);
            setSelectedIndicatorInfo({source: defaultIndicator.source, description: defaultIndicator.description, unitMeasure: defaultIndicator.unitMeasure, dataCom: defaultIndicator.territorialScale.COM, DataReg: defaultIndicator.territorialScale.REG});
            setIndicators(MAS[selectedMAS - 1].indicators.map(indicator => ({ ...indicator })));
        }
    }, [selectedMAS, MAS]);

    useEffect(() => {
        if (selectedIndicator){
            // console.log("AllIndicators: ", allIndicators);
            // console.log("Indicatori del mas selezionato:  ", indicators);
            // console.log(`Indicatore ${selectedIndicator.id} - ${selectedIndicator.name} è stato settato correttamente.`);
            async function loadAnalyticsData() {
                try {
                    const analyticsData = await getIndicatorsAnalytics();
                
                    // console.log("AnalyticsData: ", analyticsData);
                        
                    if (!analyticsData) {
                        return;
                    }
                    setAnalyticsData(analyticsData);
                    // Find the selected indicator in the analytics data
                    const selectedAnalytics = analyticsData[selectedIndicator.id];
                
                    if (selectedAnalytics) {
                        // Get minYear and maxYear from the selected indicator
                        const { minYear, maxYear, minValue, maxValue } = selectedAnalytics;
                
                        setMinYear(minYear);
                        setMaxYear(maxYear);
                        setMinValue(minValue);
                        setMaxValue(maxValue);
                
                    } else {
                        console.error('Invalid analytics data:', selectedAnalytics);
                    }
                } catch (error) {
                    console.error('Failed to fetch data:', error);
                }
            }
            
            loadAnalyticsData();
        }
    }, [selectedIndicator]);

    const handleIndicatorChange = (indicatorId) => {
        // console.log(`Tutti gli indicatori: ${JSON.stringify(indicators, null, 2)} `);
        // console.log(`Tutti gli indicatori: ${JSON.stringify(indicators, null, 2)} `);
        // console.log(`IndicatoreId: ${indicatorId}.`);
        const indicator = indicators.find(indicator => indicator.id === indicatorId);
        // console.log(`Indicatore ${indicator.id} - ${indicator.name} | ${indicator.source} - ${indicator.unitMeasure} è stato cliccato.`);
        setSelectedIndicator(indicator);
        setSelectedIndicatorInfo({source: indicator.source, description: indicator.description, unitMeasure: indicator.unitMeasure, dataCom: indicator.territorialScale.COM, dataReg: indicator.territorialScale.REG})
    };

  


    return (
        <IndicatorsContext.Provider value={{ allIndicators, indicators, selectedIndicator, analyticsData, selectedIndicatorInfo, minYear, maxYear, minValue, maxValue, handleIndicatorChange}}>
        {children}
        </IndicatorsContext.Provider>
    );
};

export default IndicatorsContext;