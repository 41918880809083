export const defaultPositions = {
    Lombardy: {
        centre: [45.69212238261098, 9.791671794843944],
        lat: 45.69212238261098, 
        lng: 9.791671794843944,
        zoom: 8
    },
    Milan: {
        centre: [45.4642035, 9.189982],
        lat: 45.4642035, 
        lng: 9.189982,
        zoom: 13
    }
  };