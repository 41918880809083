// MapControls.js
import React, {useContext} from 'react';
import Slider from 'react-input-slider';
import chroma from 'chroma-js';
import styles from './MapControls.module.css';
import MASContext from '../context/MASContext';
import MediaQueryContext from '../context/MediaQueryContext';


const MapControls = ({ year, minYear, maxYear, handleYearChange }) => {

    const { isMobileScreen } = useContext(MediaQueryContext);
    const { colorSelectedMAS } = useContext(MASContext);

    return (
        <div className={isMobileScreen ? styles.sliderContainerMobile : styles.sliderContainer}>
            <div className={isMobileScreen ? styles.sliderRowMobile : styles.sliderRow}>
            <span className={isMobileScreen ? styles.rangeYearLabelMobile : styles.rangeYearLabel}>{minYear}</span>
            <Slider
                className="slider"
                axis="x"
                x={year}
                xmin={minYear}
                xmax={maxYear}
                onChange={handleYearChange}
                style={{ width: '50vw' }}
                styles={{
                track: {
                    backgroundColor: chroma(colorSelectedMAS).brighten(2).css()
                },
                active: {
                    backgroundColor: colorSelectedMAS
                }
                }}
                // marks={years.map((year) => ({ value: year }))}
            />
            <span className={isMobileScreen ? styles.rangeYearLabelMobile : styles.rangeYearLabel}>{maxYear}</span>
            </div>
            <span className={isMobileScreen ? styles.yearLabelMobile : styles.yearLabel} >{year}</span>
        </div>
    );
};

export default MapControls;
