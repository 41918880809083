import React from 'react';
import styles from './Contacts.module.css'; 
import contacts from '../data/Contacts';


const Contacts = () => (
  <div className={styles.contacts}>
    {Object.entries(contacts).map(([key, { logo, name, sede, telephone, fax, site, pec }]) => (
      <div key={key} className={styles.contact}>
        <div className={styles.contacLogo}>
          <img src={logo} alt={name} className={styles.contactLogoImg} />
        </div>
        <p className={styles.contactInfo} dangerouslySetInnerHTML={{ __html: sede }} />
        <p className={styles.contactInfo}>Centralino: {telephone}</p>
        <p className={styles.contactInfo}>fax: {fax}</p>
        <a href={site} className={styles.contactInfo}>{site}</a>
        <p className={styles.contactInfo}>{pec}</p>
      </div>
    ))}
  </div>
);

export default Contacts;