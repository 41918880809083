import React, { useState, useContext, useEffect } from 'react';
import MASContext from '../context/MASContext';
import styles from './IndicatorsBar.module.css';
import MediaQueryContext from '../context/MediaQueryContext';
import MenuContext from '../context/MenuContext';
import IndicatorsContext from '../context/IndicatorsContext';

const IndicatorsSidebar = () => {
  const { isTabletScreen, isMobileScreen } = useContext(MediaQueryContext);
  const { MAS, selectedMAS, colorSelectedMAS } = useContext(MASContext);
  const { indicators, selectedIndicator, selectedIndicatorInfo, handleIndicatorChange } = useContext(IndicatorsContext);
  const { handleButtonClick, activeButton, setMenuItems } = useContext(MenuContext);
  const [imageSrc, setImageSrc] = useState('');
  const [title, setTitle] = useState('');
  
  const [hoveredIndicator, setHoveredIndicator] = useState(null);

  useEffect(() => {
    if (isMobileScreen) {
      setMenuItems(MAS.map((mas) => (
        <button>
        </button>
      )));
    }
  }, [isMobileScreen, activeButton]);

  useEffect(() => {
    if (MAS[selectedMAS - 1]) {
      setImageSrc(MAS[selectedMAS - 1].icon);
      setTitle(MAS[selectedMAS - 1].description);
    }
  }, [selectedMAS, MAS]);
  
  // useEffect(() => {
  //   if (MAS[selectedMAS - 1]) {
  //     c
  //   }
  // }, [selectedIndicator, selectedMAS, MAS]);

  const renderIndicators = (indicators) => {
    if (!Array.isArray(indicators)) {
      return null;
    }
    return indicators.map((indicator, index) => {
      const isSelected = selectedIndicator.name === indicator.name;
      const isHovered = hoveredIndicator === indicator.name;
      return (
        <div
          className={`${styles.indicator} ${isSelected ? styles.selected : ''}`}
          key={index}
          onClick={() => handleIndicatorChange(indicator.id)}
          onMouseEnter={() => setHoveredIndicator(indicator.name)}
          onMouseLeave={() => setHoveredIndicator(null)}
          data-umami-event = {`Indicator-${indicator.id}`}
          style={{
            backgroundColor: isSelected || isHovered ? colorSelectedMAS : 'initial',
            color: isSelected || isHovered ? '#ffffff' : 'initial',
            fontWeight: isSelected ? 'bold' : 'normal'
          }}
        >
          {indicator.name}
        </div>
      );
    });
  };

  return (
    isMobileScreen ? null :
      <div className={styles.sidebar}>
        <div className={styles.image_container} style={{backgroundColor: colorSelectedMAS}}>
          <img src={imageSrc} alt="MAS" className={styles.image} />
          <h2 className={styles.title}>{title}</h2>
        </div>
        
        <div className={styles.indicators_container}>{renderIndicators(indicators)}</div>
        <hr style={{ borderColor: colorSelectedMAS }} className={styles.separator} />
        <h4>Informazioni indicatore</h4>
        <div className={styles.info_section}>
          
          <p>{selectedIndicatorInfo.description}</p>
          <p>Fonte: {selectedIndicatorInfo.source} </p>
          <p>Unità di misura: {selectedIndicatorInfo.unitMeasure} </p>
        </div>
      </div>
  );
};

export default IndicatorsSidebar;