import React, { useState, useContext, useEffect } from 'react';
import styles from './Sidebar.module.css';
import MediaQueryContext from '../context/MediaQueryContext'; 
import MenuContext from '../context/MenuContext';
import MASContext from '../context/MASContext'; 

const Sidebar = () => {
  const { isTabletScreen, isMobileScreen } = useContext(MediaQueryContext);
  const { MAS } = useContext(MASContext);
  const { handleButtonClick, activeButton } = useContext(MenuContext);

  // useEffect(() => {
  //   if (isMobileScreen) {
  //     setMenuItems(MAS.map((mas) => (
  //       <button
  //         key={`mobile-${mas.id}`}
  //         className={`${styles.mobileSidebar_button} ${activeButton === mas.id ? styles.active : ''}`}
  //         style={{ backgroundColor: mas.color }}
  //         onClick={() => handleButtonClick(mas.id, mas.color)}
  //       >
  //         <img className={styles.mobileIcon} src={mas.icon} alt={mas.id} />
  //         <div className={styles.mobileNameID}>{mas.name}</div>
  //       </button>
  //     )));
  //   }
  // }, [isMobileScreen, activeButton]);

  return (      
    isMobileScreen ? null :
      <div className={`${styles.sidebar} ${isMobileScreen ? styles.mobileSidebar : ''}`}>
        {MAS.map((mas) => (
          <button
            key={mas.id}
            className={`${styles.sidebar_button} ${activeButton === mas.id ? styles.active : ''}`}
            style={{ backgroundColor: mas.color }}
            data-umami-event = {`MAS${mas.id}`}
            onClick={() => handleButtonClick(mas.id, mas.color)}
          >
            <img className={styles.icon} src={mas.icon} alt={mas.id} />
            <div className={styles.nameID}>{mas.name}</div>
          </button>
        ))}
      </div>
  );
}

export default Sidebar;