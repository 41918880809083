import geojsonData from '../data/geoJsonMunicipality.json';
import { decodeStringsInObject } from '../utils/cleanData.js';

export async function getMASIndicatorMetadata() {
    const response = await fetch('https://anciapi.colouree.cloud/api/endpoints/metadata');
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    // console.log("Not cleaned data: ",data);
    decodeStringsInObject(data);
    // console.log("Cleaned data: ",data);
    return data;
}
// export async function getMASIndicatorMetadata() {
//   const response = await fetch('https://anciapi.colouree.cloud/api/endpoints/metadata');
//   if (!response.ok) {
//     throw new Error(`HTTP error! status: ${response.status}`);
//   }
//   const data = await response.json();
//   const decodedData = JSON.parse(JSON.stringify(data), (key, value) => {
//     if (typeof value === 'string') {
//       try {
//         return decodeURIComponent(escape(value));
//       } catch (error) {
//         console.error(`Error decoding string "${value}": ${error}`);
//         return value;
//       }
//     } else {
//       return value;
//     }
//   });
//   console.log(decodedData);
//   return decodedData;
// }


//could be useful in the future using a cache system for the api
export function getGeoJson() {   //add async if you have to use the api instead of the json file
    // const response = await fetch('https://anciapi.colouree.cloud/api/endpoints/geometries');
    // if (!response.ok) {
    //   throw new Error(`HTTP error! status: ${response.status}`);
    // }
    // const data = await response.json();
    const data = geojsonData;
    decodeStringsInObject(data);
    return data;
}

export async function getMAPData(indicatorId, year) {
  const minGrade = 1;
  const maxGrade = 10;
  const response = await fetch(`https://anciapi.colouree.cloud/api/endpoints/dataByIndicatorYear/params?indicator=${indicatorId}&year=${year}&minGrade=${minGrade}&maxGrade=${maxGrade}`);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const waitResponse = await response.json();
  const data = waitResponse.results;

  // console.log("Data witho no results: ", data);
  return data;
}

export async function getIndicatorsAnalytics() {
  const response = await fetch('https://anciapi.colouree.cloud/api/endpoints/indicatorsAnalytics');
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const waitResponse = await response.json();
  const data = waitResponse.results;
  // console.log("Analytics: ", data);
  return data;

}

export async function getIndicatorData(selectedIndicatorCode, istatCode) {
  const response = await fetch(`https://anciapi.colouree.cloud/api/endpoints/indicatorByIstatCode/params?indicator=${selectedIndicatorCode}&istatCode=${istatCode}`);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const waitResponse = await response.json();
  const data = waitResponse.results;
  // console.log("IndicatorData: ", data);
  return data;
}

export async function getRadarData(istatCode) {
  const minGrade = 1;
  const maxGrade = 10;
  const response = await fetch(`https://anciapi.colouree.cloud/api/endpoints/radarByIstatCode/params?istatCode=${istatCode}&minGrade=${minGrade}&maxGrade=${maxGrade}`);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const waitResponse = await response.json();
  const data = waitResponse.results;
  // console.log("RadarData: ", data);
  return data;

}

export function getIndicatorsByCode(indicators, codiceComune) {
  // Itera attraverso gli elementi della variabile indicators
  for (let i = 0; i < indicators.length; i++) {
      // Controlla se l'elemento corrente è un oggetto e ha una proprietà con il nome "Codice"
      if (typeof indicators[i] === 'object' && 'Codice' in indicators[i]) {
          // Se il codice corrisponde a quello cercato, restituisci i dati relativi a quel codice
          if (indicators[i].Codice === codiceComune) {
              return indicators[i];
          }
      }
  }

  // Se il codice comune non è stato trovato, restituisci null o un valore di default
  return null;
}

export const generateTableData = (indicators, municipalitySelected) => {
  // Verifica se l'indicatore selezionato è "Indice di mortalità in incidente stradale"
      // Ottieni i dati per Italia (codice IT)
      const italyData = getIndicatorsByCode(indicators, "IT");

      //Lombardy data
      const lombardyData = getIndicatorsByCode(indicators, "03");

      //setted for Milan
      const municipalityCode = "015146";

      // Ottieni i dati per il comune selezionato
      const municipalityData = getIndicatorsByCode(indicators, municipalityCode);

      // Ottieni gli anni da italiaData
      const years = Object.keys(italyData);

      // Crea la nuova struttura dei dati per la tabella
      const tableData = years.map(year => ({
          year,
          Italia: italyData[year] || 0,
          Lombardia: lombardyData[year] || 0,
          Milano: municipalityData[year] || 0, //[municipalitySelected] instead of "Milano" when municipalitySelected is not null
      }));

      return { tableData, years };
};