import React, { useState, useEffect } from 'react';
import { getMASIndicatorMetadata } from '../data/APIdata';

const MASContext = React.createContext();

export const MASProvider = ({ children }) => {
  const [MAS, setMAS] = useState([]);
  const [selectedMAS, setSelectedMAS] = useState(1);
  const [colorSelectedMAS, setColorSelectedMAS] = useState(null);

  useEffect(() => {
    const fetchMASIndicatorMetadata = async () => {
      const data = await getMASIndicatorMetadata();
      const MASData = data.results.datastructure;
      setMAS(MASData);
      const initialMAS = MASData[0]; // Default MAS
      setSelectedMAS(initialMAS.id);
      setColorSelectedMAS(initialMAS.color);
    };

    fetchMASIndicatorMetadata();
  }, []);

  const handleMASClick = (masId, color) => {
    // console.log(`MAS ${masId} è stato cliccato.`);
    setSelectedMAS(masId);
    setColorSelectedMAS(color);
  };

  return (
    <MASContext.Provider value={{ MAS, selectedMAS, colorSelectedMAS, handleMASClick }}>
      {children}
    </MASContext.Provider>
  );
};

export default MASContext;