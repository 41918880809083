import React, { useContext } from 'react';
import styles from './Table.module.css';
// import { CSVLink } from "react-csv";
// import { Parser } from 'json2csv';
import Papa from 'papaparse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import MASContext from '../context/MASContext';
import IndicatorsContext from '../context/IndicatorsContext';

const Table = ({municipalitySelected, istatCode, tableData, years, locations}) => {
  const { selectedIndicator} = useContext(IndicatorsContext);
  // const { MAS, selectedMAS } = useContext(MASContext);


  // Function to format data for CSV (used only to convert data in Excel)
  const exportToExcel = (data) => {

    // Convert data from array of objects to array of arrays
    const headers = Object.keys(data[0]);
    const rows = data.map(obj => Object.values(obj));
    const aoaData = [headers, ...rows];

    // Create workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(aoaData);

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Generate Excel file
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Convert Uint8Array to Blob
    const blob = new Blob([wbout], {type: 'application/octet-stream'});

    //Save the file using FileSaver.js
    saveAs(blob, municipalitySelected ? `${selectedIndicator.name}-${municipalitySelected}.xlsx` : `${selectedIndicator.name}.xlsx`);
  };

  const exportToCSV = (data) => {
    const csv = Papa.unparse(data);
  
    // Convert CSV string to Blob
    const blob = new Blob([csv], {type: 'text/csv'});
  
    // Save the file using FileSaver.js
    saveAs(blob, municipalitySelected ? `${selectedIndicator.name}-${municipalitySelected}.csv` : `${selectedIndicator.name}.csv`);
  };

  // console.log("Locations2: ", locations);

  if (municipalitySelected && !locations.includes(municipalitySelected)) {
    locations.push(municipalitySelected);
  }
  
  // console.log("Locations3: ", locations);

  return (
    <div className={styles.tableSection}> 
      <div className={styles.buttonContainer}>
        <button className={styles.downloadbtn} onClick={() => exportToExcel(tableData)} data-umami-event = {`DownloadData`} data-umami-event-format = {`Excel`} data-umami-event-indicator = {`Indicator-${selectedIndicator.id}`} data-umami-event-municipality = {municipalitySelected ? `Municipality-${istatCode}` : ``}> 
          <FontAwesomeIcon icon={faFileExcel} color="green" size="lg"/>
        </button>
        {/* <CSVLink className={styles.downloadbtn} filename = {municipalitySelected ? `${selectedIndicator.name}_COM_${municipalitySelected}.csv` : `${selectedIndicator.name}.csv`}  data={tableData} data-umami-event = {`DownloadData`} data-umami-event-format = {`Csv`}  data-umami-event-indicator = {`Indicator-${selectedIndicator.id}`} data-umami-event-municipality = {municipalitySelected ? `Municipality-${istatCode}` : ``}> 
          <FontAwesomeIcon icon={faFileCsv} color="green" />
        </CSVLink> */}
        <button className={styles.downloadbtn} onClick={() => exportToCSV(tableData)} data-umami-event = {`DownloadData`} data-umami-event-format = {`Csv`}  data-umami-event-indicator = {`Indicator-${selectedIndicator.id}`} data-umami-event-municipality = {municipalitySelected ? `Municipality-${istatCode}` : ``}> 
          <FontAwesomeIcon icon={faFileCsv} color="green" size="lg"/>
        </button>
      </div>

      <div className={styles.tableContainer}>
        
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Location</th>
              {years && years.map((year, index) => (
                <th key={index}>{year}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {locations.map((location, index) => (
              <tr key={index}>
                <td>{location}</td>
                {tableData && tableData.map((item, index) => (
                  <td key={index}>{item[location] || 'N.D.'}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    
  );
};

export default Table;