import React, { useState, useContext, useEffect } from 'react';
import MASContext from './MASContext';

const MenuContext = React.createContext();

export const MenuProvider = ({ children }) => {
    const { handleMASClick, MAS } = useContext(MASContext);
    const [activeButton, setActiveButton] = useState('');
    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
      if (MAS.length > 0) {
        setActiveButton(MAS[0].id);
      }
    }, [MAS]);
    

    const handleButtonClick = (masId, color) => {
        handleMASClick(masId, color);
        setActiveButton(masId);
    };

    return (
        <MenuContext.Provider value={{ activeButton, menuItems, handleButtonClick, setMenuItems }}>
        {children}
        </MenuContext.Provider>
    );
};

export default MenuContext;