import React, { useState } from 'react';
import Header from '../UI/Header';
import Footer from '../UI/Footer';
import { colors } from '../utils/colors';
import Contacts from '../components/Contacts';

const ContactsPage = () => {

  return (
    <div>
      <Header />
      <Contacts />
      <Footer />
    </div>
  );
};

export default ContactsPage;