import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FiMenu } from "react-icons/fi";
import { IoGridSharp } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import MediaQueryContext from '../context/MediaQueryContext';
import styles from "./Header.module.css";
import MenuContext from '../context/MenuContext';
import MASContext from '../context/MASContext';
import Logo from '../assets/landing/wheelSdg.webp';
import { FeedbackFarm } from "@feedbackfarm/react";

function Header() {
  const { isMobileScreen } = useContext(MediaQueryContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMASMenuOpen, setIsMASMenuOpen] = useState(false);
  const { MAS } = useContext(MASContext);
  const { handleButtonClick, menuItems, activeButton } = useContext(MenuContext);
  const [clickedButton, setClickedButton] = useState(null);

  // useEffect(() => {
  //   if (isMobileScreen) {
  //     function handleClickOutside(event) {
  //       const dropdownMenu = document.querySelector('.dropdownMenu');
  //       const dropdownMASMenu = document.querySelector('.dropdownMASMenu');
  
  //       if (dropdownMenu && !dropdownMenu.contains(event.target) && dropdownMASMenu && !dropdownMASMenu.contains(event.target)) {
  //         // Click was outside the menus, close them
  //         setIsMenuOpen(false);
  //         setIsMASMenuOpen(false);
  //       }
  //     }
  
  //     // Add the event listener when the component mounts
  //     document.addEventListener('click', handleClickOutside);
  
  //     // Remove the event listener when the component unmounts
  //     return () => {
  //       document.removeEventListener('click', handleClickOutside);
  //     };
  //   }
  // }, [isMobileScreen]);

  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);
    if (isMASMenuOpen) setIsMASMenuOpen(false);
  };
  
  const toggleMASMenu = () => {
    setIsMASMenuOpen(prevState => !prevState);
    if (isMenuOpen) setIsMenuOpen(false);
  };

  return (
    <nav>
      <ul className={styles.nav_list}>
        <div className={styles.logo_wrapper}>
        <NavLink to="/" className={styles.nav_link}>
          <img src={Logo} alt="Logo" className={styles.logo} />
        </NavLink>
        </div>

        <FeedbackFarm
          endImageUrl=""
          identifier=""
          localization={{"placeholder":{"BUG":"Vorrei segnalare un errore ... (aggiungi in calce il tuo nome)","FEATURE":"Vorrei proporre ... (aggiungi in calce il tuo nome)","OTHER":"Altro ... (aggiungi in calce il tuo nome)","DEFAULT":"Ho un suggerimento ... (aggiungi in calce il tuo nome)"},"headerTitle":"Feedback","sendAnotherFeedbackButtonText":"Invia un altro feedback","sendFeedbackButtonText":"Invia","submitFeedbackMessage":"il tuo feedback è stato inviato!","submittedFeedbackHeaderTitle":"Grazie"}}
          pageName=""
          projectId="oFhe9cyTTNHVH29D0A42"
          theme={{"backgroundColor":"#ffffff","borderColor":"#D1D1D1","buttonBackgroundColor":"#22C197","buttonTextColor":"#FFFFFF","disabledButtonBackgroundColor":"#D1D1D1","disabledButtonTextColor":"#A7A7A7","textColor":"#000000","typeBackgroundColor":"#FCFBFA"}}
          types={[{"imageUrl":"/svg/zap.svg","text":"Proposte","type":"FEATURE"},{"imageUrl":"/svg/bug.svg","text":"Errori","type":"BUG"},{"imageUrl":"/svg/monkey.svg","text":"Altro","type":"OTHER"}]}
        >
          <button className={styles.feedbackButton}>Lasciaci un feedback</button> 
        </FeedbackFarm>

        {isMobileScreen ? (
          <div className={styles.icons_mobile}>
            {location.pathname ==='/cruscotto' && (
              <>
                <IoGridSharp className={styles.icon} onClick={toggleMASMenu}/>
                {isMASMenuOpen && MAS && (
                  <div className={styles.dropdownMASMenu}>
                    {MAS.map((mas) => (
                      <button
                        key={`mobile-${mas.id}`}
                        className={`${styles.mobileSidebar_button} ${activeButton === mas.id ? styles.activeMASButton : ''}`}
                        style={{ backgroundColor: mas.color }}
                        data-umami-event = {`MAS${mas.id}`}
                        onClick={() => handleButtonClick(mas.id, mas.color)}
                      >
                        <img className={styles.mobileIcon} src={mas.icon} alt={mas.id} />
                        <div className={styles.mobileNameID}>{mas.name}</div>
                      </button>
                    ))}
                  </div>
                )}
              </>
            )}
            

            <FiMenu className={styles.icon} onClick={toggleMenu} />
            {isMenuOpen && (
              <div className={styles.dropdownMenu}>
                <NavLink 
                  to="/cruscotto" 
                  className={`${styles.nav_link_mobile} ${isActive('/cruscotto') ? styles.nav_link_active : ''}`} 
                >
                  Cruscotto
                </NavLink>
                <NavLink 
                  to="/indicatori" 
                  className={`${styles.nav_link_mobile} ${isActive('/indicatori') ? styles.nav_link_active : ''}`} 
                >
                  Indicatori
                </NavLink>
                <NavLink 
                  to="/contatti" 
                  className={`${styles.nav_link_mobile} ${isActive('/contatti') ? styles.nav_link_active : ''}`} 
                >
                  Contatti
                </NavLink>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.nav_items}>
            <NavLink 
              to="/cruscotto" 
              className={`${styles.nav_link} ${isActive('/cruscotto') ? styles.nav_link_active : ''}`} 
            >
              Cruscotto
            </NavLink>
            <NavLink 
              to="/indicatori" 
              className={`${styles.nav_link} ${isActive('/indicatori') ? styles.nav_link_active : ''}`} 
            >
              Indicatori
            </NavLink>
            <NavLink 
              to="/contatti" 
              className={`${styles.nav_link} ${isActive('/contatti') ? styles.nav_link_active : ''}`} 
            >
              Contatti
            </NavLink>
            {/* <NavLink 
              to="/dashboard" 
              className={`${styles.nav_link} ${isActive('/dashboard') ? styles.nav_link_active : ''}`} 
            >
              Cruscotto
            </NavLink>
            <NavLink 
              to="/indicators" 
              className={`${styles.nav_link} ${isActive('/indicators') ? styles.nav_link_active : ''}`} 
            >
              Indicatori
            </NavLink>
            <NavLink 
              to="/contacts" 
              className={`${styles.nav_link} ${isActive('/contacts') ? styles.nav_link_active : ''}`} 
            >
              Contatti
            </NavLink> */}
          </div>
        )}
      </ul>
    </nav>
  );
}

export default Header;