import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import DashboardPage from './pages/DashboardPage';
import IndicatorsPage from './pages/IndicatorsPage';
import ContactsPage from './pages/ContactsPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/cruscotto" element={<DashboardPage />} />
        <Route path="/indicatori" element={<IndicatorsPage />} />
        <Route path="/contatti" element={<ContactsPage />} />
      </Routes>
    </Router>
  );
}

export default App;

//try to set up a routing by url to set an indicator as active and a section (map, table, chart) on the dashboard page