import React from 'react';

// Creiamo un contesto con valori di default
const MediaQueryContext = React.createContext({
  isDesktopOrLaptop: false,
  isBigScreen: false,
  isTablet: false,
  isMobile: false,
  isPortrait: false,
  isRetina: false,
});

export default MediaQueryContext;