import React, { useState, useEffect } from 'react';
import { getMASIndicatorMetadata } from '../data/APIdata';
import styles from './ListIndicators.module.css';

function ListIndicators() {
  const [masData, setMasData] = useState([]);
  const [clickedButtonMas, setClickedButtonMas] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const data = await getMASIndicatorMetadata();
      setMasData(data.results.datastructure);
    }
    fetchData();
  }, []);

  // Add this useEffect to set the first MAS as clicked by default
  useEffect(() => {
    if (masData.length > 0) {
      setClickedButtonMas(masData[0]);
    }
  }, [masData]);

  return (
    <div className={styles.container}>
      <div className={styles.masButtons}>
        {masData.map(mas => (
          <button
            key={mas.id}
            className={`${styles.masButton} ${clickedButtonMas === mas ? styles.clicked : ''}`} 
            style={{ backgroundColor: mas.color}}
            onClick={() => setClickedButtonMas(mas)}
            title={mas.description} 
          >
            <img src={mas.icon} alt={mas.description} />
            {mas.name}
          </button>
        ))}
      </div>
      {clickedButtonMas && (
        <div className={styles.indicators}>
          {clickedButtonMas.indicators.map(indicator => (
            <details key={indicator.id} className={styles.indicator} style={{ backgroundColor: `${clickedButtonMas.color}33` }}>
                <summary>{indicator.name}</summary>
                <p>{indicator.description}</p>
                <p>Unità di misura: {indicator.unitMeasure}</p>
                <p>Fonte: {indicator.source}</p>
                {indicator.notes && <p>Note: {indicator.notes}</p>}
            </details>
          ))}
        </div>
      )}
    </div>
  );
}

export default ListIndicators;



