import React from 'react';
import Header from '../UI/Header';
import LandingContent from '../components/LandingContent';
import Footer from '../UI/Footer';



const HomePage = () => {
  return (
    <div>
      <Header />
      <LandingContent />
      <Footer />
    </div>
  );
};

export default HomePage;
