import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import { getColor } from '../utils/colors';
import { createGradesLegend } from '../utils/legendGrades';
import { useMap} from 'react-leaflet';

const MapLegend = ({ municipalitySelected, selectedIndicator, selectedIndicatorInfo, minValue, maxValue }) => {
    const map = useMap();
    map.attributionControl.setPrefix('');
    const legendRef = React.useRef();
    
    
    useEffect(() => {
      if(legendRef.current) map.removeControl(legendRef.current);
      // console.log('minValue:', minValue);
      // console.log('maxValue:', maxValue);
      
      // if (!legendRef.current) {
      const legend = L.control({ position: "bottomright" });
  
      legend.onAdd = function () {
        const div = L.DomUtil.create("div", "info legend");
  
        // Add a title to the legend
        div.innerHTML = `<h3 style="text-align: center;">${selectedIndicatorInfo.unitMeasure}</h3>`;
  
        // Add a semi-transparent white background
        div.style.backgroundColor = 'rgba(255, 255, 255, 0.7)';
        div.style.padding = '6px 8px';
        div.style.border = '1px solid rgba(0, 0, 0, 0.2)';
        div.style.borderRadius = '3px';
        
        const totalGrades = municipalitySelected ? 3 : 10;
        const grades = createGradesLegend(minValue, maxValue, totalGrades);
        const colors = grades.map((grade) => getColor(grade, municipalitySelected, grades));
  
        for (let i = 0; i < grades.length - 1; i++) {
          div.innerHTML +=
            '<i style="background:' +
            colors[i] +
            '; width: 18px; height: 18px; float: left; margin-right: 8px;"></i> ' +
            grades[i] + (i < grades.length - 2 ? ' - ' + (grades[i + 1]).toFixed(1) : ' - ' + grades[i + 1]) + "<br>";
        }
  
        return div;
      };
  
      legend.addTo(map);
      legendRef.current = legend;
  
    }, [selectedIndicator, minValue, maxValue]);
  
    return null;
}

export default MapLegend;





// import React, { useEffect, useState } from 'react';
// import L from 'leaflet';
// import { getColor } from '../utils/colors';
// import { createGrades } from '../utils/legendGrades';
// import { useMap} from 'react-leaflet';

// const MapLegend = ({ legend, setLegend, map, municipalitySelected, selectedIndicator, selectedIndicatorInfo, minValue, maxValue }) => {
//     // const map = useMap();
//     // map.attributionControl.setPrefix('');
//     // const legendRef = React.useRef();
    
    
//     useEffect(() => {
//         if (!map) return;
//     //   if(legendRef.current) map.removeControl(legendRef.current);
    
//         if (legend) {
//             map.removeControl(legend);
//         }
//         console.log('minValue:', minValue);
//         console.log('maxValue:', maxValue);
        
//         // if (!legendRef.current) {
//         const newLegend = L.control({ position: "bottomright" });
    
//         newLegend.onAdd = function () {
//             const div = L.DomUtil.create("div", "info legend");
    
//             // Add a title to the legend
//             div.innerHTML = `<h3 style="text-align: center;">${selectedIndicatorInfo.unitMeasure}</h3>`;
    
//             // Add a semi-transparent white background
//             div.style.backgroundColor = 'rgba(255, 255, 255, 0.7)';
//             div.style.padding = '6px 8px';
//             div.style.border = '1px solid rgba(0, 0, 0, 0.2)';
//             div.style.borderRadius = '3px';
            
//             const totalGrades = municipalitySelected ? 3 : 10;
//             const grades = createGrades(minValue, maxValue, totalGrades);
//             const colors = grades.map((grade) => getColor(grade, municipalitySelected, grades));
    
//             for (let i = 0; i < grades.length - 1; i++) {
//             div.innerHTML +=
//                 '<i style="background:' +
//                 colors[i] +
//                 '; width: 18px; height: 18px; float: left; margin-right: 8px;"></i> ' +
//                 grades[i] + (i < grades.length - 2 ? ' - ' + (grades[i + 1]).toFixed(1) : ' - ' + grades[i + 1]) + "<br>";
//             }
    
//             return div;
//         };
//         console.log("map: ", map);
//         if(map) {
//             setTimeout(() => {
//                 if(legend) legend.addTo(map);
//             }, 5000); // delay of 1 second
//           }
//         setLegend(newLegend);
//         // legendRef.current = legend;
//         return () => {
//             if (legend) {
//               map.removeControl(legend);
//             }
//         };
    
//     }, [selectedIndicator, minValue, maxValue]);
  
//     return null;
// }

// export default MapLegend;