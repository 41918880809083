import React, { useState } from 'react';
import Sidebar from '../UI/Sidebar';
import IndicatorsBar from '../UI/IndicatorsBar';
import Header from '../UI/Header';
import Footer from '../UI/Footer';
import Dashboard from '../components/Dashboard';

const DashboardPage = () => {

  return (
    <>
      <Header />
      <Sidebar />
      <Dashboard />
      <IndicatorsBar />
      <Footer />
    </>
  );
};

export default DashboardPage;


// const [selectedMAS, setSelectedMAS] = useState(MAS[0].id);
  // const [colorSelectedMAS, setColorSelectedMAS] = useState(MAS[0].color);
  // const [selectedIndicator, setSelectedIndicator] = useState(MAS[0].indicators[0].name);

  // const handleMASClick = (masId, color) => {
  //   // Implementa la logica per gestire il clic del MAS qui
  //   console.log(`MAS ${masId} è stato cliccato.`);
  //   setSelectedMAS(masId);
  //   setColorSelectedMAS(color);
  //   setSelectedIndicator(MAS[masId-1].indicators[0].name); 
  // };

  // const handleIndicatorChange = (indicator) => {
  //   console.log(`Indicatore ${indicator} è stato selezionato.`);
  //   setSelectedIndicator(indicator);
  // };
