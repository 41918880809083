import React, { useState } from 'react';
import Header from '../UI/Header';
import Footer from '../UI/Footer';
import { colors } from '../utils/colors';
import ListIndicators from '../components/ListIndicators';

const IndicatorsPage = () => {

  

  return (
    <div>
      <Header />
      <ListIndicators />
      <Footer />
    </div>
  );
};

export default IndicatorsPage;